import Choices from "choices.js/src/scripts/choices";
import { debounce } from "debounce";

import { json, status } from "../../fetchUtils";
import idempotence from "../../idempotence";
import ready from "../../ready";

const affiliationSearch = {
  init() {
    document
      .querySelectorAll("[data-js-affiliation-search]")
      .forEach((input) => {
        this.initInput(input);
      });
  },

  initInput(input) {
    if (idempotence.guard(input, "affiliation-search")) return;

    const choices = new Choices(input, {
      allowHTML: false,
      searchChoices: false,
      shouldSort: false,
    });
    const noChoicesOption = document.querySelector(".has-no-choices");
    if (noChoicesOption) noChoicesOption.classList.add("d-none");
    input.addEventListener(
      "search",
      debounce((e) => {
        const noResultsOption = document.querySelector(".has-no-results");
        if (noResultsOption) noResultsOption.classList.add("d-none");
        if (!e.detail || !e.detail.value || !e.detail.value.length) {
          choices.setChoices([], "id", "name", true);
          if (noResultsOption) noResultsOption.classList.remove("d-none");
        } else {
          fetch(`/members/search?q=${encodeURIComponent(e.detail.value)}`, {
            method: "GET",
          })
            .then(status)
            .then(json)
            .then((response) => {
              choices.setChoices(response.members, "id", "name", true);
              if (noResultsOption) noResultsOption.classList.remove("d-none");
            })
            .catch(() => {
              choices.setChoices([], "id", "name", true);
            });
        }
      }, 250)
    );
  },
};

ready(() => {
  affiliationSearch.init();
});

export default affiliationSearch;
