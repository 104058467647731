import nodeListToArray from "./nodeListToArray";
import ready from "./ready";

ready(() => {
  FontAwesome.dom.i2svg();

  nodeListToArray(document.querySelectorAll("[data-disable-with]")).forEach(
    (disableWith) => {
      disableWith.addEventListener("click", () => {
        FontAwesome.dom.i2svg();
      });
    }
  );
});

document.addEventListener("nested:fieldAdded", () => {
  FontAwesome.dom.i2svg();
});
